import axiosHeaders from "@/helpers/axiosInterceptors";
import axiosErrorHandler from "@/helpers/axiosErrorHandler"
import { vueToast } from "@/helpers/utils";
import { router } from "@/main.js";
import {encrypt} from "@/helpers/crypt";

const state = {
    clients:{
        clients: null,
        clients_filter: null,
        client_profile: null,
        client_cards: null,
        client_orders: null,
        client_operations: null,
        clients_header_map: {
          fullName: {
            text: "Full name",
            value: "fullname",
            type: "fullname",
            width: "300px",
            sortable: false,
          },
          phone: {
            text: "Phone",
            value: "phone",
            width: "180px",
            sortable: false,
            copy: true,
          },
          email: {
            text: "E-mail",
            value: "email",
            width: "130px",
            sortable: false,
          },
          clientStatus: {
            text: "Status",
            value: "clientStatus",
            width: "130px",
            sortable: false,
          },
          limits: {
            text: "Limits",
            value: "limits",
            width: "130px",
            sortable: false,
          },
          residentCountry: {
            text: "Resident Country",
            value: "residentCountry",
            width: "130px",
            sortable: false,
          },
        },
        documents_by_country_code: null,
        documents_list: null,
    }
  };
  
  const mutations = {
    SET_CLIENTS_FILTER( state, payload ) {
      state.clients.clients_filter = payload.properties
    },

    SET_CLIENTS_LIST( state, payload ) {
      state.clients.clients = payload
    },

    SET_CLIENT_PROFILE(state, payload) {
      state.clients.client_profile = payload
    },

    SET_CLIENT_CARDS(state, payload) {
      state.clients.client_cards = payload
    },

    SET_CLIENT_ORDERS(state, payload) {
      state.clients.client_orders = payload
    },

    SET_CLIENT_OPERATIONS(state, payload) {
      state.clients.client_operations = payload
    },

    CLEAR_CLIENT_PROFILE(state) {
      state.clients.client_profile = null
      state.clients.client_cards = null
      state.clients.client_orders = null
      state.clients.client_operations = null
    },

    CLEAR_CLIENTS_STATE(state) {
      state.clients.clients = null
      state.clients.clients_filter = null
    },

    SET_CLIENT_DOCUMENTS_BY_COUNTRY_CODE(state, payload) {
      state.clients.documents_by_country_code = payload
    },

    SET_CLIENT_DOCUMENTS_LIST(state, payload) {
      state.clients.documents_list = payload
    },
    
    CLEAR_CLIENT_DOCUMENTS_LIST(state) {
      state.clients.documents_list = null
    },
  };
  
  const actions = {
    async getClientsFilter({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "faxoze5r",
          "method" : "get",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_CLIENTS_FILTER", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },

    async getClients({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "faxoze5r",
          "method" : "post",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_CLIENTS_LIST", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },

    async getClientById({ commit }, id) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `ufzpw1o4/${id}`,
          "method" : "get",
          "params" : {}
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })

        if(status === 200) {
          commit("SET_CLIENT_PROFILE", data.client)
        }

      } catch (e) {
        if(e.response?.status === 403) {
          axiosErrorHandler(e)
        } else {
          vueToast(`${e.response?.data?.errors?.[0].subject}: ${e.response?.data?.errors?.[0].message}`, "error");
          router.push("/clients");
        }
      }
    },

    async getClientCards({ commit }, obj) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `a25i09me/${ obj.id }`,
            "method" : "post",
            "params" : {
              skip: obj.skip,
              take: obj.take,
              dateFrom: obj.dateFrom,
              dateTo: obj.dateTo
            }
        }))
          const { status, data } = await axiosHeaders.post('/api/request', {
            data: crypto,
          })

          if(status === 200) {
            commit("SET_CLIENT_CARDS", data)
          }

        } catch (e) {
          console.log(e?.response)
        }
    },

    async getClientOrders({ commit }, obj) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `h0wjf0bm/${ obj.id }`,
          "method" : "post",
          "params" : {
            skip: obj.skip,
            take: obj.take,
            dateFrom: obj.dateFrom,
            dateTo: obj.dateTo
          }
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })

        if(status === 200) {
          commit("SET_CLIENT_ORDERS", data)
        }

      } catch (e) {
        console.log(e?.response)
      }
    },

    async getClientOperations({ commit }, obj) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `u2j51y57/${ obj.id }`,
          "method" : "post",
          "params" : {
            skip: obj.skip,
            take: obj.take,
            dateFrom: obj.dateFrom,
            dateTo: obj.dateTo
          }
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })

        if(status === 200) {
          commit("SET_CLIENT_OPERATIONS", data)
        }

      } catch (e) {
        console.log(e?.response)
      }
    },

    async getClientDocsByCountryCode({ commit }, id) {
      try {

        const crypto = await encrypt(JSON.stringify({
          "uri" : `uu3pjxo7/${ id }`,
          "method" : "get",
          "params" : {}
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })

        if(status === 200) {
          commit("SET_CLIENT_DOCUMENTS_BY_COUNTRY_CODE", data)
        }

      } catch (e) {
        console.log(e?.response)
      }
    },

    async getClientDocumentsList({ commit, dispatch }, id) {
      try {

        const crypto = await encrypt(JSON.stringify({
          "uri" : `oi2kjqw9/${ id }`,
          "method" : "get",
          "params" : {}
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })

        if(status === 200) {
					let formatData = data

					if(formatData?.documents?.length) {
						const isDocIdNew = formatData.documents?.filter(el => el.status === "New")
						const isDocIdAccepted = formatData.documents.filter(el => el.status === "Accepted")

						if (isDocIdNew?.length && !isDocIdAccepted?.length) {
							for(const i in formatData.documents) {
								const documentStatus = await dispatch('_axiosGetDocumentStatus', formatData.documents[i]?.amlDocumentRequestId)
								const result = documentStatus?.resultData?.Result

								if (result && +result !== 0){
									const ResultCode = documentStatus?.resultData?.ResultCode
									formatData.documents[i].status = 'Declined'
									formatData.documents[i].rejectReason = ResultCode.replace('KycRequestStatus.', '')
								}
							}
						}
					}

          commit("SET_CLIENT_DOCUMENTS_LIST", formatData)
        }

      } catch (e) {
        console.log(e?.response)
      }
    },

		async _axiosGetDocumentStatus(_, id) {
			try {

				const crypto = await encrypt(JSON.stringify({
					"uri" : `iausdau7/${ id }`,
					"method" : "get",
					"params" : {}
				}))
				const { status, data } = await axiosHeaders.post('/api/request', {
					data: crypto,
				})

				if(status === 200) {
					return data
				}

			} catch (e) {
				console.log(e?.response)
			}
		},


  };
  
  
  const getters = {
    clients: (state) => state.clients.clients,
    clientsFilter: (state) => state.clients.clients_filter,
    clientProfile: (state) => state.clients.client_profile,
    clientsHeaderMap: (state) => state.clients.clients_header_map,
    clientCards: (state) => state.clients.client_cards,
    clientOrders: (state) => state.clients.client_orders,
    clientOperations: (state) => state.clients.client_operations,
    clientDocumentsByCountryCode: (state) => state.clients.documents_by_country_code,
    clientDocumentsList: (state) => state.clients.documents_list?.documents?.reverse(),
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  