<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8.3335 1.66699L8.3335 3.33366L4.16683 3.33366L4.16683 16.667L8.3335 16.667L8.3335 18.3337L4.16683 18.3337C3.24183 18.3337 2.50016 17.592 2.50016 16.667L2.50016 3.33366C2.50016 2.41699 3.24183 1.66699 4.16683 1.66699L8.3335 1.66699Z"
        :fill="iconColor"/>
    <path
        d="M17.4998 9.96732L12.9498 5.34232L11.7748 6.52565L14.3748 9.13398L6.6665 9.13398L6.6665 10.8007L14.3748 10.8007L11.7748 13.4007L12.9582 14.584L17.4998 9.96732Z"
        :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconTransferMoney",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
