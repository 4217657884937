import LAYOUT from "@/layout/layout";
import Settings from "../../layout/Settings";

export default [
  {
    path: "/settings",
    component: LAYOUT.base,
    meta: { auth: true },
    children: [
      {
        path: "",
        name: "settings",
        component: Settings,
        children: [
          {
            path: "profile",
            name: "profile",
            component: () => import(/* webpackChunkName: '' */ "../../views/Profile.vue"),
          },
          {
            path: "users",
            name: "users",
            meta: { admin: true },
            component: () => import(/* webpackChunkName: '' */ "../../views/Users.vue"),
          },
          {
            path: "teams",
            name: "teams",
            meta: { admin: true },
            component: () => import(/* webpackChunkName: '' */ "../../views/Teams.vue"),
          },
          {
            path: "/settings/teams/:id",
            name: "team",
            meta: { admin: true },
            component: () => import(/* webpackChunkName: '' */ "../../views/Team.vue"),
          },
          {
            path: "/settings/team-info/",
            name: "team",
            meta: { admin: false },
            component: () => import(/* webpackChunkName: '' */ "../../views/TeamInfo.vue"),
          },
        ]
      }
    ],

  },
];
