import LAYOUT from "@/layout/layout";
 
export default [
  {
    path: "/clients",
    component: LAYOUT.base,
    meta: { auth: true, permissions: "clients-list-view" },
    children: [
      {
        path: "",
        name: "clients",
        component: () => import(/* webpackChunkName: '' */ "@/modules/Clients/views/Clients.vue"),
      },
      {
        path: "/clients/:id",
        name: "client",
        component: () => import(/* webpackChunkName: '' */ "@/modules/Clients/views/Client.vue"),
      },

    ],

  },
];
