import Vue from 'vue'

export function vueToast(txt, type = "error") {
    Vue.$toast.open({
        message: txt,
        type,
        position: "bottom",
    });
}

export function transformDate(value) {
    const arr = value.split("/");
    const el = arr.shift();
    arr.splice(1, 0, el);
    return arr.join("/");
}

export function isExchangeAvailable() {
    const hour = Vue.prototype.$moment(new Date()).tz("Europe/London").format("HH");
    return +hour < 16 && +hour >= 9;
}

export function debounce(func, delay) {
    let timerId;
  
    return function(...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
    
        timerId = setTimeout(() => {
            func.apply(this, args);
            timerId = null;
        }, delay);
    };
}
  
  