import axiosHeaders from "@/helpers/axiosInterceptors";
import axiosErrorHandler from "@/helpers/axiosErrorHandler"
import { vueToast } from "@/helpers/utils"
import {encrypt} from "@/helpers/crypt";

const state = {
    cards:{
        cards: null,
        cards_filter: null,

        cards_details_loading: false,
        cards_details: null,
        is_card_detail: false,

        pan_cvv: null,
        cards_header_map: {
          maskedPan: {
            text: "Masked Pan",
            value: "maskedPan",
            width: "140px",
            style: "font-weight-bold",
            sortable: false,
            copy: true,
          },
          firstName: {
            text: "Card Holder Name",
            value: "cardHolderName",
            width: "180px",
            sortable: false,
          },
          createdDate: {
            text: "Created Date",
            value: "createdDate",
            width: "100px",
            type: "createdDate",
            sortable: false,
          },
          expirationDate: {
            text: "Expiration Date",
            value: "expirationDate",
            type: "expirationDate",
            width: "100px",
            sortable: false,
          },
          publicToken: {
            text: "Public Token",
            value: "publicToken",
            width: "140px",
            sortable: false,
            copy: true,
          },
          LimitOverallCurrency: {
            text: "Limit overall currency",
            width: "60px",
            type: "LimitOverallCurrency",
            sortable: false,
          },
          limitOverall: {
            text: "Limit Overall",
            width: "60px",
            type: "limitOverall",
            sortable: false,
          },
          limitActual: {
            text: "Spending amount",
            width: "60px",
            type: "limitActual",
            sortable: false,
          },
          status: {
            text: "Status",
            value: "status",
            width: "100px",
            type: "status",
            sortable: false,
          },
        },

        art_work: null,
    }
  };
  
  const mutations = {
    SET_CARDS_FILTER( state, payload ) {
      state.cards.cards_filter = payload.properties
    },
    SET_CARDS_LIST( state, payload ) {
      state.cards.cards = payload
    },
    CHANGE_CARDS_DETAILS_LOADING(state) {
      state.cards.cards_details_loading = !state.cards.cards_details_loading
    },
    SET_CARDS_DETAILS(state, payload) {
      state.cards.cards_details = payload
      state.cards.is_card_detail = true
    },
    DESTROY_CARDS_DETAILS(state) {
      state.cards.cards_details = null
      state.cards.pan_cvv = null
      state.cards.is_card_detail = false
    },
    SET_DEFAULT_CARDS_INFO(state) {
      state.cards.cards_details = {
        statusHistory: [
          {
            id: 0,
            userId: "",
            userName: "",
            cardId: 0,
            oldStatus: "",
            newStatus: "",
            reason: "",
            date: "",
          },
        ],
        card: {
          id: 0,
          clientId: "",
          maskedPan: "",
          publicToken: "",
          name: "",
          firstName: "",
          lastName: "",
          issueDate: "",
          createdDate: "",
          expirationDate: "",
          deliveryDate: "",
          paymentSystem: 0,
          cardOwnerType: 0,
          type: "",
          status: 0,
          cmsStatus: 0,
          cardType: {
            id: 0,
            name: "",
            materialType: "",
            virtualType: "",
          },
          limitRule: {
            limitCurrency: "",
            limitCashDaily: 0,
            limitCashWeekly: 0,
            limitCashMonthly: 0,
            limitCashAnnually: 0,
            limitCashMaxOperation: 0,
            limitDaily: 0,
            limitWeekly: 0,
            limitMonthly: 0,
            limitAnnually: 0,
            limitMaxOperation: 0,
            limitOverall: 0,
          },
          limitActual: {
            limitCurrency: "",
            limitCashDaily: 0,
            limitCashWeekly: 0,
            limitCashMonthly: 0,
            limitCashAnnually: 0,
            limitDaily: 0,
            limitWeekly: 0,
            limitMonthly: 0,
            limitAnnually: 0,
            limitOverall: 0,
          },
          isUsedForLastTopUp: false,
          locationBasedSecurity: false,
          swipePayments: false,
          contactlessPayments: false,
          atmWithdrawals: false,
          onlineTransactions: false,
          cutoffDate: "",
          partnerClientId: "",
          cardDeliveryAddress: "",
        },
      }
    },
    SET_PAN_CVV(state, payload) {
      state.cards.pan_cvv = payload
    },
    UPDATE_STATUS_CARD(state, payload) {
      if(payload.method == 'unfreeze') {
        state.cards.cards_details.card.status = 4
      }
      if(payload.method== 'freeze') {
        state.cards.cards_details.card.status = 5
      }
      if(payload.method == 'close-by-token') {
        state.cards.cards_details.card.status = 6
      }
    },
    SET_CARD_ART_WORK(state, payload) {
      state.cards.art_work = payload
    },
    CLEAR_CARD_ART_WORK(state) {
      state.cards.art_work = null
    },
    CLEAR_CARDS_STATE(state) {
      state.cards.cards_filter = null
      state.cards.cards = null
    }
  };
  
  const actions = {
    async getCardsFilter({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "649omijt",
          "method" : "get",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_CARDS_FILTER", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },
    async getCards({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "649omijt",
          "method" : "post",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_CARDS_LIST", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },

    //details card
    async getDetailsCard({ commit }, cardId) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `637v2o2p/${cardId}`,
          "method" : "get",
          "params" : { cardId }
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_CARDS_DETAILS", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },

    //get pan
    async getPanCvv({ commit }, params) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : 'pvd9zlg6',
          "method" : "post",
          "params" : params,
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_PAN_CVV", data)
        }
      } catch(e) {
        vueToast(e.response?.data?.errors?.[0].message)
      }
    },

    // save setting limitcard
    async saveLimitSetting({commit}, params) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : '32exy7pv',
          "method" : "post",
          "params" : params,
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          console.log(status, data)
          console.log("commit", commit)
        }
      } catch(e) {
        vueToast(e.response?.data?.errors?.[0].message)
      }
    },

    // save setting status card
    async saveSettingStatusCard({commit}, obj) {
      const eventType = {
        'activate': '1dmzjwm0',
        'unfreeze': 'zzl0qnkf',
        'freeze': '3kyeiv1d',
        'close-by-token': 'w2t71i2s'
      }
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : eventType[obj.method],
          "method" : "post",
          "params" : obj.params,
        }))
        const { status } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200 && obj.card) {
          commit('UPDATE_STATUS_CARD', obj)
        }
      } catch (e) {
        vueToast(e.response?.data?.errors?.[0].message)
      }
    },

    // save security status card
    async saveSecuritySetting({commit}, params) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `7jqbcsql/${params.clientId}`,
          "method" : "post",
          "params" : { token: params.token, ...params.security },
        }))
        const { status } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          console.log(commit)
        }
      } catch(e) {
        vueToast(e.response?.data?.errors?.[0].message)
      }
    },

    async getArtWork({commit}, params) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : `m9fj7rbe`,
          "method" : "post",
          "params" : params,
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit('SET_CARD_ART_WORK', data)
        }
      } catch(e) {
        vueToast(e.response?.data?.errors?.[0].message)
      }
    }

  };
  
  const getters = {
    cards: (state) => state.cards.cards,
    cardsFilter: (state) => state.cards.cards_filter,
    cardsDetailsLoading: (state) => state.cards.cards_details_loading,
    cardsDetails: (state) => state.cards.cards_details,
    panCvv: (state) => state.cards.pan_cvv,
    isCardDetail: (state) => state.cards.is_card_detail,
    cardsHeaderMap: (state) => state.cards.cards_header_map,
    cardsArtWork: (state) => state.cards.art_work
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  