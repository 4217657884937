import axiosHeaders from "@/helpers/axiosInterceptors";
import axiosErrorHandler from "@/helpers/axiosErrorHandler"
import {encrypt} from "@/helpers/crypt";

const state = {
    operations:{
        operations: null,
        operations_filter: null,
        operations_header_map: {
          clientFirstName: {
            text: "First Name",
            value: "clientFirstName",
            width: "180px",
            type: "clientFirstName",
            sortable: false,
          },
          clientLastName: {
            text: "Last Name",
            value: "clientLastName",
            width: "180px",
            type: "clientLastName",
            sortable: false,
          },
          operationDate: {
            text: "Operation date",
            value: "operationDate",
            width: "136px",
            type: "operationDate",
            sortable: false,
          },
          referenceNumber: {
            text: "Transaction reference",
            value: "referenceNumber",
            width: "173px",
            type: "referenceNumber",
            sortable: false,
          },
          debitAmount: {
            text: "Debit Amount",
            value: "debitAmount",
            width: "130px",
            sortable: false,
          },
          creditAmount: {
            text: "Credit Amount",
            value: "creditAmount",
            width: "130px",
            sortable: false,
          },
          currency: {
            text: "Currency",
            value: "currency",
            width: "92px",
            sortable: false,
          },
          narrative: {
            text: "Narrative",
            value: "narrative",
            width: "160px",
            sortable: false,
          },
          accountNumber: {
            text: "Account",
            value: "accountNumber",
            width: "130px",
            sortable: false,
          },
          originalTransactionAmount: {
            text: "Original Amount",
            value: "originalTransactionAmount",
            width: "130px",
            sortable: false,
          },
          originalTransactionCurrency: {
            text: "Original Currency",
            value: "originalTransactionCurrency",
            width: "147px",
            sortable: false,
          },
          cardMaskedPan: {
            text: "Card Masked Pan",
            value: "cardMaskedPan",
            width: "147px",
            sortable: false,
          },
          mccCode: {
            text: "MCC",
            value: "mccCode",
            width: "120px",
            sortable: false,
          },
          mccDescription: {
            text: "MCC Description",
            value: "mccDescription",
            width: "190px",
            sortable: false,
          },
        },
    }
  };
  
  const mutations = {
    SET_OPERATIONS_FILTER( state, payload ) {
      state.operations.operations_filter = payload.properties
    },
    SET_OPERATIONS_LIST( state, payload ) {
      state.operations.operations = payload
    },
    CLEAR_OPERATIONS_STATE(state) {
      state.operations.operations = null
      state.operations.operations_filter = null
    }
  };
  
  const actions = {
    async getOperationsFilter({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "rgp6grq6",
          "method" : "get",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_OPERATIONS_FILTER", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    },
    async getOperations({ commit }, params = {}) {
      try {
        const crypto = await encrypt(JSON.stringify({
          "uri" : "rgp6grq6",
          "method" : "post",
          "params" : params
        }))
        const { status, data } = await axiosHeaders.post('/api/request', {
          data: crypto,
        })
        if(status === 200) {
          commit("SET_OPERATIONS_LIST", data)
        }
      } catch(e) {
        axiosErrorHandler(e)
      }
    }
  };
  
  const getters = {
    operations: (state) => state.operations.operations,
    operationsFilter: (state) => state.operations.operations_filter,
    operationsHeaderMap: (state) => state.operations.operations_header_map
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  