<template>
  <div class="settings">
    <div class="settings__sidebar">
      <Sidebar />
    </div>
    <div class="settings__body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/UI/Sidebar";
export default {
  name: "Settings",

  components: {
    Sidebar,
  },
};
</script>
