import merge from "lodash/merge";
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

import App from "./App.vue";
import common_store from './store'
import i18n from "./lang/i18n";
import { extra_modules_list } from "./modules/custom_modules_list";

import moment from "moment-timezone";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueMask from 'v-mask'
import coreRouter from "./router/router";

import VueCookies from "vue-cookies";
import vueScroll from "./plugins/vueScroll";
import Moment from "vue-moment"
import VueClipboard from 'vue-clipboard2'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import "./scss/fonts.scss";
import "./scss/main.scss";
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import {Breadcrumbs, FunctionToString, HttpContext} from '@sentry/vue';
import {Config} from '@/constants/config';

import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

// for develop mode
import axios from "axios";
axios.defaults.withCredentials = true
axios.defaults.baseURL = Config.VUE_APP_BASEURL;

// import modules stores
let modules_stores = {};

const connectModuleStore = (module) => {
  try {
    const module_store = require(`./modules/${module.component_name}/store/store.js`);

    if (module_store && module_store.default) {
      modules_stores = merge(modules_stores, module_store.default);
    }
  } catch (e) {
    // console.log('store file not found');
  }
};


// import modules routers
const modules_routers = [];
const connectModuleRoute = (module) => {
  try {
    const module_router = require(`./modules/${module.component_name}/router/router.js`);

    if (module_router && module_router.default) {
      modules_routers.push(module_router.default);
    }
  } catch (e) {
    // console.log('store file not found');
  }
};

// add modules if ACTIVE
extra_modules_list
  .filter((module) => module && module.active && module.component_name)
  .forEach((module) => {
    connectModuleStore(module);
    connectModuleRoute(module);
  });


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.debug = true;

Vue.use(VueRouter);
Vue.use(Vuex);

Vue.use(VueCookies);
Vue.use(vueScroll);
Vue.use(Moment, { moment });
Vue.use(VueClipboard)
Vue.use(VueToast);
Vue.use(VueMask);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

export const router = new VueRouter({
  mode: "history",
  routes: [...coreRouter, ...modules_routers.flat()],
});

router.beforeEach((to, from, next) => {
  const localData = Vue.$cookies.get("auth_token_jwt");
  const requireAuth = to.matched.some(record => record.meta.auth)
  const requireAdmin = to.matched.some(record => record.meta.admin)
  const pagePermissions = to.matched[0]?.meta?.permissions
  const userPermissions = JSON.parse(localStorage.getItem("permissions"))
  const isAdminLocal = localStorage.getItem("admin")
  const isAdmin = isAdminLocal === "true" ? true : false

  if(requireAuth && !localData) {
    next('/login')
  } else if((!isAdmin && userPermissions && userPermissions[pagePermissions] === 0) || (!isAdmin && requireAdmin)) {
    next('/welcome')
  } else {
    next()
  }
})

export const appStore = new Vuex.Store({
  modules: {
    common: common_store,
    app: merge(
      modules_stores,
    ),
  },
});

Sentry.init({
  Vue,
  dsn: Config.VUE_APP_SENTRY_DSN,
  logErrors: true,
  attachProps: true,
  attachStacktrace: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "dzing-vcip.evergreen.team", /^\//],
    }),
    new Breadcrumbs({
      console: true,
      fetch: true,
      sentry: true,
      xhr: true,
    }),
    new HttpContext(),
    new FunctionToString(),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.3 : 1,
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
  debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
  enabled: process.env.NODE_ENV !== 'development',
});

new Vue({
  router,
  i18n,
  store: appStore,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
