/*
 Modules Controls
*/

// List of modules and their activity
const extra_modules_list = [
    {
        component_name: "MasterAccounts",
        active: true,
    },
    {
        component_name: "Orders",
        active: true,
    },
    {
        component_name: "Operations",
        active: true,
    },
    {
        component_name: "Clients",
        active: true,
    },
    {
        component_name: "Cards",
        active: true,
    },
    {
        component_name: "Settings",
        active: true,
    },
    {
        component_name: "Welcome",
        active: true,
    }
];

module.exports = { extra_modules_list };
  