import axios from "axios";
import Vue from "vue";
import * as Sentry from '@sentry/vue';
import {encrypt} from "@/helpers/crypt";
import { router } from "@/main.js";

const state = {
  rememberMe: false,

  user_id: null,
  token: null,
  refreshToken: null,
  expiresIn: null,

  // Variables for interceptor refresh token
  isTokenRefreshed: false,
  failedInterceptorsQueue: [],

  // end Variables for interceptor refresh token

  first_name: "",
  last_name: "",

  authorized: false,

  is_admin: "empty",

  errors: [],

  languagesMain: [],
  timezones: [],

  // dynamic component DynamicSnackbar.vue
  snackbar: {
    text: "",
    color: "",
    timeout: "",
  },

  avatar: null,

  selectedFilters: null,
  isMobileOverflow: false,

  update_white_space: false,
  current_white_space: null,
  all_white_space: null,
  show_2fa: false,
  isOverflow: false,
};

const mutations = {

  SET_REMEMBER_ME(state, value) {
    state.rememberMe = value;
  },

  SET_USER_ID(state, value) {
    state.user_id = value;
  },

  SET_TOKEN(state, value) {
    state.token = value;
  },

  SET_REFRESH_TOKEN(state, value) {
    state.refreshToken = value;
  },

  SET_FIRST_NAME(state, value) {
    state.first_name = value;
  },

  SET_LAST_NAME(state, value) {
    state.last_name = value;
  },

  SET_EXPIRES_DATE(state, value) {
    state.expiresIn = value
  },

  SET_2FA_STATE(state, value) {
    state.show_2fa = value
  },

  SET_IS_ADMIN(state) {
    const admin = localStorage.getItem("admin")
    state.is_admin = admin == 'true' ? true : false
  },

  LOGOUT(state) {
    state.user_id = "";
    state.token = "";
    state.refreshToken = "";
    state.authorized = false;
  },

  SET_AUTHORIZED(state, value) {
    state.authorized = value;
  },

  SET_LANGUAGES(state, payload) {
    state.languagesMain = payload;
  },

  SET_TIMEZONES(state, payload) {
    state.timezones = payload;
  },

  SET_AVATAR(state, payload) {
    state.avatar = payload;
  },

  IS_TOKEN_REFRESHED_TRUE(state) {
    state.isTokenRefreshed = true;
  },

  IS_TOKEN_REFRESHED_FALSE(state) {
    state.isTokenRefreshed = false;
  },

  SET_FAILED_INTERCEPTORS_QUEUE_EMPTY(state) {
    state.failedInterceptorsQueue = [];
  },

  PUSH_TO_INTERCEPTORS_QUEUE_EMPTY(state, obj) {
    state.failedInterceptorsQueue.push(obj);
  },

  SHOW_SNACKBAR_MESSAGE(state, payload) {
    state.snackbar.text = payload.text;
    state.snackbar.color = payload.color;
    state.snackbar.timeout = payload.timeout;
  },

  SET_SELECTED_FILTERS( state, payload) {
    state.selectedFilters = payload
  },

  DESCTROY_ACTIVE_FILTER(state) {
    state.selectedFilters = null
  },

  SET_MOBILE_OVERFLOW(state) {
    state.isMobileOverflow = !state.isMobileOverflow
  },

  SET_CURRENT_WHITE_SPACE(state) {
    state.current_white_space = localStorage.getItem("active_spaces")
  },

  SET_ALL_WHITE_SPACES(state) {
    state.all_white_space = JSON.parse(localStorage.getItem("spaces"))
  },

  UPDATE_WHITE_SPACE(state) {
    state.update_white_space = !state.update_white_space
  },

  CHANGE_IS_OVERFLOW_STATE(state) {
    state.isOverflow = !state.isOverflow
  },
};

const actions = {

  axiosLogin({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/login`, data)
        .then((response) => {
          // add data to state
          dispatch("_authAddState", response.data);
          // add data to cookie
          dispatch("_authAddCookies");
          // //get Tokens for User Inbox
          // const respToInbox =  response.data
          // respToInbox['email'] = data?.email
          // dispatch("axiosInboxAuthTokens", respToInbox)
          Sentry.setUser({
            id: response?.data?.id,
            username: response?.data?.username,
            email: response?.data?.email,
          });
          resolve(response);
        })
        .catch((error) => {
          // if(error?.response?.data?.errors[0]?.msg == "User is already logged in") {
          //   router.push("/master");
          //   return
          // }
          reject(error);
        });
    });
  },

  axiosLogin2FA({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/login`, data)
        .then((response) => {
          // add data to state
          dispatch("_authAddState", response);

          // add data to cookie
          dispatch("_authAddCookies");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosInitResetPassword(_, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/auth/init-reset-password`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  axiosPasswordComplexity(_, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/auth/password-complexity`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosResetPassword(_, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/auth/reset-password`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  _authAddState({ commit }, response) {
    commit("SET_USER_ID", response?.data?.user?.id);
    commit("SET_TOKEN", response.data?.authorization?.token);
    // commit("SET_REFRESH_TOKEN", response.data.refreshToken);
    commit("SET_FIRST_NAME", response?.data?.user?.firstname);
    commit("SET_LAST_NAME", response?.data?.user?.lastname);
    commit("SET_AUTHORIZED", true);
    commit("SET_EXPIRES_DATE", response?.data?.authorization?.expiresIn)
    commit("SET_2FA_STATE", response?.data?.user?.two_fa_auth)
  },

  _authAddCookies({ state }) {
    console.log("SET KOOKIE")
    // set expired for cookies
    const expiredDate = state.expiresIn
    // if (state.rememberMe) {
    //   expiredDate.setDate(expiredDate.getDate() + Number(process.env.VUE_APP_LOGIN_WITH_RM_DAYS));
    // } else {
    //   expiredDate.setHours(expiredDate.getHours() + Number(process.env.VUE_APP_LOGIN_WITHOUT_RM_HOURS));
    // }

    // Set Cookie for user_id, jwtToken and refreshToken
    const token = encrypt(state.token)
    Vue.$cookies.set("auth_user_id", state.user_id, expiredDate);
    Vue.$cookies.set("auth_token_jwt", token, expiredDate, null, null, true, true, "Lax");
    Vue.$cookies.set("first_name", state.first_name, expiredDate);
    Vue.$cookies.set("last_name", state.last_name, expiredDate);
    Vue.$cookies.set("show_2fa", state.show_2fa, expiredDate);

    // Vue.$cookies.set("auth_refresh_token", state.refreshToken, expiredDate);

    // remember_me in Cookie
    Vue.$cookies.set("auth_remember_me", state.rememberMe, expiredDate);

    // authorized
    Vue.$cookies.set("auth_authorized", state.authorized, expiredDate);
  },

  async logout({ commit }, push = true) {
    commit("LOGOUT");
    Vue.$cookies.remove("auth_user_id");
    Vue.$cookies.remove("auth_token_jwt");
    Vue.$cookies.remove("auth_refresh_token");
    Vue.$cookies.remove("first_name");
    Vue.$cookies.remove("last_name");
    Vue.$cookies.remove("show_2fa");
    localStorage.removeItem("admin");
    localStorage.removeItem('spaces');
    localStorage.removeItem('active_spaces');
    localStorage.removeItem("permissions");
    localStorage.removeItem("team_id");
    commit("CLEAR_MASTER_STATE")
    commit("CLEAR_ORDERS_STATE")
    commit("CLEAR_OPERATIONS_STATE")
    commit("CLEAR_CARDS_STATE")
    commit("CLEAR_CLIENTS_STATE")

    try {
     await axios.get('/api/users/logout')
    } catch(e) {
      console.error(e);
    }


    // remove user from Sentry
    Sentry.configureScope((scope) => scope.setUser(null));

    if (push) {
      router.push({ name: "login" });
    }
  },

  async setPassword({ commit }, data) {
    console.log(data)
    try {
      const res = await axios.post('/api/users/set-password', data)
      console.log(res)
      commit("LOGOUT");
    } catch(e) {
      console.error(e);
    }
  },

  authorizedStatus({ commit }, value) {
    commit("SET_AUTHORIZED", value);
  },

  axiosLanguagesAndTimezones({ commit }) {
    return new Promise((resolve, reject) => {
      const token = Vue.$cookies.get("auth_token_jwt");

      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(`/api/init/user-general-info`, config)
        .then((response) => {
          commit("SET_LANGUAGES", response.data.languages);
          commit("SET_TIMEZONES", response.data.timeZones);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosGetAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      const token = Vue.$cookies.get("auth_token_jwt");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      };

      axios.get(`/api/users/photo`, config)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.request.response], { type: response.headers["content-type"] }),
          );
          commit("SET_AVATAR", url);
          resolve(response);
        })
        .catch((error) => {
          commit("SET_AVATAR", null);
          console.error('axiosGetAvatar, error:', error);
          if (error.status === 404){
            return false
          }
          reject(error.status);
        });
    });
  },

  axiosSetAvatar ({ dispatch }, data ) {
    return new Promise((resolve, reject) => {
      const token = Vue.$cookies.get("auth_token_jwt");

      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.put(`/api/users/photo`, data, config)
        .then((response) => {
          dispatch('axiosGetAvatar')
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosDeleteAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      const token = Vue.$cookies.get("auth_token_jwt");

      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.delete(`/api/users/photo`, config)
        .then((response) => {
          commit("SET_AVATAR", null);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  isTokenRefreshedTrue({ commit }) {
    commit("IS_TOKEN_REFRESHED_TRUE");
  },

  isTokenRefreshedFalse({ commit }) {
    commit("IS_TOKEN_REFRESHED_FALSE");
  },

  makeEmptyFailedInterceptorsQueue({ commit }) {
    commit("SET_FAILED_INTERCEPTORS_QUEUE_EMPTY");
  },

  /**
   * @name pushToFailedInterceptorsQueue
   * @description action for push to array in interceptors.js
   * @param commit
   */
  pushToFailedInterceptorsQueue({ commit }, obj) {
    commit("PUSH_TO_INTERCEPTORS_QUEUE_EMPTY", obj);
  },

  processInterceptionQueue({ dispatch, getters }, { error, token = null }) {
    getters.getFailedInterceptorsQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    dispatch("makeEmptyFailedInterceptorsQueue");
  },

  // end Variables for interceptor refresh token

  showSnackbar({ commit }, payload) {
    commit("SHOW_SNACKBAR_MESSAGE", payload);
  },

  async axiosInboxAuthTokens (_, payload) {
    //get User for Inbox
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + Number(process.env.VUE_APP_LOGIN_WITH_RM_DAYS));

    await axios.post(`/auth/login_crm`, payload)
      .then((resp) => {
        console.log(resp, 'auth/login_crm')
        Vue.$cookies.set("token_c", resp?.data?.data?.token, expiredDate);

        axios
          .post(
            `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/get_engine_token/`,
            {
              id: 1
            },
            {
              headers: {
                'X-CSRF-TOKEN': resp?.data?.data?.token,
              },
            }
          )
          .then((r) => {
            console.log(r, '/kw/get_engine_token/')
            Vue.$cookies.set("token_c", r?.data?.data?.token, expiredDate);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

const getters = {

  authorized: (state) => state.authorized,

  firstName: (state) => state?.first_name || "",

  lastName: (state) => state?.last_name || "",

  languagesMain: (state) => state.languagesMain,

  timezones: (state) => state.timezones,

  userAvatar: (state) => state.avatar,

  getIsTokenRefreshed: (state) => state.isTokenRefreshed,

  getFailedInterceptorsQueue: (state) => state.failedInterceptorsQueue,

  activeFilter: (state) => state.selectedFilters,

  isMobileOverflow: (state) => state.isMobileOverflow,

  updateWhiteLabel: (state) => state.update_white_space,

  activeSpace: (state) => state.current_white_space,

  listSpaces: (state) => state.all_white_space?.filter(el => el !== state.current_white_space),

  isOverflow: (state) => state.isOverflow,

  isAdmin: (state) => state.is_admin

};

export default {
  state,
  mutations,
  actions,
  getters,
};
