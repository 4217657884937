<template>
  <svg :width="width" :height="height" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0C5.80078 0 3.30859 1.90625 0.25 5C2.88281 7.64453 5.09375 10 9 10C12.9023 10 15.7734 7.01562 17.75 5.05469C15.7266 2.75781 12.8594 0 9 0ZM9 8.56641C7.07031 8.56641 5.5 6.96484 5.5 5C5.5 3.03125 7.07031 1.43359 9 1.43359C10.9297 1.43359 12.5 3.03516 12.5 5C12.5 6.96875 10.9297 8.56641 9 8.56641Z" :fill="iconColor"/>
    <path d="M9 3.75C9 3.44141 9.11328 3.16016 9.29688 2.94141C9.19922 2.92578 9.10156 2.91797 9 2.91797C7.875 2.91797 6.95703 3.85156 6.95703 5C6.95703 6.14844 7.875 7.08203 9 7.08203C10.125 7.08203 11.043 6.14844 11.043 5C11.043 4.91016 11.0352 4.82031 11.0273 4.73047C10.8125 4.89844 10.5469 5 10.2539 5C9.55859 5 9 4.44141 9 3.75Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconXls",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
