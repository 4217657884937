import axiosHeaders from "@/helpers/axiosInterceptors";
import { vueToast } from "@/helpers/utils"

const state = {
    settings: {
      users: null,
      teams: null,
      team_by_id: null,
      permissions_for_edit: null,
      auth_permissions: null,
    }
  };
  
  const mutations = {
    SET_USERS_LIST(state, payload) {
      state.settings.users = payload
    },

    SET_TEAMS_LIST(state, payload) {
      state.settings.teams = payload
    },

    SET_TEAM_BY_ID(state, payloaad) {
      state.settings.team_by_id = payloaad
    },

    UPDATE_TEAM(state, payload) {
      state.settings.team_by_id.limitOverall = payload.limitOverall
      state.settings.team_by_id.name = payload.name
    },

    CLEAR_TEAM_BY_ID(state) {
      state.settings.team_by_id = null
    },

    SET_CURRENT_USER_PERMISSIONS_FOR_EDIT(state, payload) {
      state.settings.permissions_for_edit = payload
    },

    SET_AUTH_PERMISSIONS(state, payload) {
      state.settings.auth_permissions = payload
    },

    SET_DEFAULT_USER_PERMISSION(state) {
      const permissions = {
        "orders-list-export": 0,
        "operations-list-view": 0,
        "master-account-view": 0,
        "operations-list-export": 0,
        "cards-list-export": 0,
        "cards-list-import": 0,
        "orders-list-view": 0,
        "clients-list-view": 0,
        "users-list-view" :0,
        "cards-list-view": 0,
        "cards-add": 0,
        "cards-pan-view": 0,
        "cards-cvv-view": 0,
        "clients-details-edit": 0,
        "clients-details-view": 0,
        "users-add": 0,
        "users-edit": 0,
        "clients-add": 0,
        "cards-details-view": 0,
        "clients-list-import": 0,
        "cards-details-edit": 0
      }
      state.settings.permissions_for_edit = permissions
    },
  };
  
  const actions = {
    // GET USERS LIST
    async getUsersLists({commit}, obj) {
      try {
        const { status, data } = await axiosHeaders.post("/api/users/list", obj
        );
        if (status === 200) {
          commit('SET_USERS_LIST', data)
        }
      } catch (e) {
        vueToast(e.response.data.message)
      }
    },

    // GET TEAMS LIST
    async getTeamsList({commit}, obj) {
      try {
        const { status, data } = await axiosHeaders.post("/api/users/teams/list", obj
        );
        if (status === 200) {
          commit('SET_TEAMS_LIST', data)
        }
      } catch (e) {
        vueToast(e.response.data.message)
      }
    },

    // ADD NEW TEAM
    async addNewTeam({commit}) {
      try {
        const { status, data } = await axiosHeaders.post("/api/users/create", {
            team_id : 1,
            email: "kuzub@evergreens.in.ua",
            permissions : {
                "clients-list-view" : 2
            }
          }
        );
        if (status === 200) {
          commit('SET_TEAMS_LIST', data)
        }
      } catch (e) {
        vueToast(e.response.data.errors[0].message)
      }
    },

    async getTeamById({ commit }, id) {
      try {
        const { data, status } = await axiosHeaders.get(`/api/users/teams/${id}`)
        if(status === 200) {
          commit('SET_TEAM_BY_ID', data)
        }
      } catch (e) {
        console.log(e)
      }
    },

    // ADD AND EDIT USER
    async getPermissions({ commit }, query = null) {
      try {
        const { status, data } = await axiosHeaders.get(`/api/users/get-permissions`, { params: query }
        );

        if(status === 200) {
          if(query) {
            commit("SET_CURRENT_USER_PERMISSIONS_FOR_EDIT", data)
          } else {
            commit("SET_AUTH_PERMISSIONS", data)
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  
  const getters = {
    users: (state) => state.settings.users,
    teams: (state) => state.settings.teams,
    teamById: (state) => state.settings.team_by_id,
    currentUserPermissions: (state) => state.settings.permissions_for_edit,
    userAuthPermissions: (state) => state.settings.auth_permissions,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  