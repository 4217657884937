var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"pa-5 pa-md-8 h5"},[_vm._v(_vm._s(_vm.$t("settings.settings_title")))]),(_vm.$vuetify.breakpoint.smAndUp)?[_c('v-divider'),_c('div',{staticClass:"py-2 px-4 py-md-4 px-md-8"},[_c('router-link',{staticClass:"sub2",class:{ active: _vm.activeLink('/settings/profile/') },attrs:{"to":"/settings/profile/"}},[_vm._v(_vm._s(_vm.$t("settings.nav.profile_settings")))])],1),_c('v-divider'),(_vm.isAdmin)?[_c('div',{staticClass:"sub2 pt-4 pb-2 px-8"},[_vm._v(" "+_vm._s(_vm.$t("settings.nav.user_managements"))+" ")]),_c('div',{staticClass:"py-2 px-8"},[_c('router-link',{staticClass:"body2",class:{ active: _vm.activeLink('/settings/users/') },attrs:{"to":"/settings/users/"}},[_vm._v(_vm._s(_vm.$t("settings.nav.users")))])],1),_c('div',{staticClass:"py-2 px-8"},[_c('router-link',{staticClass:"body2",class:{
            active: _vm.activeLink('/settings/teams/'),
          },attrs:{"to":"/settings/teams/"}},[_vm._v(_vm._s(_vm.$t("settings.nav.teams")))])],1),_c('v-divider')]:_vm._e(),(!_vm.isAdmin && _vm.isTeamId)?[_c('div',{staticClass:"py-2 px-4 py-md-4 px-md-8"},[_c('router-link',{staticClass:"sub2",class:{ active: _vm.activeLink('/settings/team-info/') },attrs:{"to":"/settings/team-info/"}},[_vm._v(_vm._s(_vm.$t("settings.nav.team_budget")))])],1)]:_vm._e()]:[_c('div',{staticClass:"px-5 mb-0 mb-sm-5"},[_c('v-select',{attrs:{"items":_vm.isAdmin
            ? _vm.mobile_nav_is_admin
            : _vm.isTeamId
            ? _vm.mobile_nav_user_team
            : _vm.mobile_nav_user_without_team,"item-text":"text","item-value":"url","menu-props":{ bottom: true, offsetY: true },"outlined":"","hide-details":"auto","dense":"","height":"48"},on:{"change":function($event){return _vm.changePage($event)}},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }