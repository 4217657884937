import axios from "axios";
import Vue from "vue";
import {decryptRoute} from "@/helpers/crypt";

const axiosHeaders = () => {
    // Create instance
    let instance = axios.create();
  
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = decryptRoute(Vue.$cookies.get("auth_token_jwt"));
        if(token) {
            config.headers.Authorization =  token;
        }
      return config;
    });
  
    return instance;
  };
  
  export default axiosHeaders();