const rootState = "operations";

const rootStore = {
  state: { [rootState]: {} },
  mutations: {},
  actions: {},
  getters: {},
};

const modulesFiles = require.context("./modules", true, /\.js$/);

const addModule = function addModulePropToStore(module) {
  return function mergeObjectProperties(propName) {
    if (propName === "state") {
      rootStore[propName][rootState] = {
        ...rootStore[propName][rootState],
        ...module.default[propName][rootState],
      };
    } else {
      rootStore[propName] = {
        ...rootStore[propName],
        ...module.default[propName],
      };
    }
  };
};

const mergeModules = function mergeModuleToStore(modulePath) {
  const module = modulesFiles(modulePath);
  const mergeToRoot = addModule(module);

  Object.keys(rootStore).forEach(mergeToRoot);
};

modulesFiles.keys().forEach(mergeModules);

export default rootStore;
