export default [
  // {
  //   path: "*",
  //   redirect: "login",
  // },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Register.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Reset.vue"),
  },
  {
    path: "/",
    name: "main",
    meta: { auth: true },
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Login.vue"),
  },
  {
    path: "*",
    redirect: { name: "login" },
  },
];
