import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/master",
    component: LAYOUT.base,
    meta: { auth: true, permissions: "master-account-view" },
    children: [
      {
        path: "",
        name: "master_accounts",
        component: () => import(/* webpackChunkName: '' */ "@/modules/MasterAccounts/views/Master.vue"),
      },
    ],

  },
];
